<template>
  <div>
    <common-header></common-header>
    <div class="download-header"></div>
    <table style="width: 100%;height: 100%;padding: 120px 72px 72px 72px;font-weight: 500;">
        <tr>
            <td style="width: 100%;height: 184px;background: url('https://www.longsqin.com/source/image/html_img/bannerAllEn.jepg');background-repeat: no-repeat;background-size: 100% 100%;"></td>
        </tr>
        <tr style="width: 100%;">
            <td style="width: 100%;height: 20px;text-align: center;font-size: 28px;color: #666;line-height: 42px;">Dear <span id="userName">{{realName}}</span> users：</td>
        </tr>
        <tr style="width: 100%;">
            <td style="width: 100%;height: 20px;text-align: center;font-size: 28px;color: #666;line-height: 42px">Hello！</td>
        </tr>
        <tr style="width: 100%;">
            <td style="width: 100%;height: 20px;text-align: center;font-size: 28px;color: #666;line-height: 42px">Your email address has been confirmed</td>
        </tr>
        <tr>
            <td style="display: flex;justify-content: center;margin:12px 0 24px 0;">
                <div style="color:#000;text-decoration: none;cursor: pointer;;width: 260px;height: 62px; background: url('https://www.longsqin.com/source/image/html_img/button.png') no-repeat;background-size: 100% 100%;text-align: center;line-height: 62px;font-size: 22px;">verified</div>
            </td>
        </tr>
        <tr style="width: 100%;">
            <td style="display: flex;justify-content: center;">
                <div style="padding: 0 24px; width: 728px; display: flex;align-items: flex-start;font-size: 20px;background-color: #e7f3fc;">
                    <img style="width: 18px;height: 18px;padding: 23px 23px 23px 0px;" src="https://www.longsqin.com/source/image/zh/orderOnline/tipsSuccessIcon.png" alt="提示icons">
                    <div style="color: #666666;padding: 17px 0;">
                        Tips: After confirming the verification email, please return to the online order page of the official website to continue to complete the payment operation.
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonFooter from "../../components/en/commonFooter.vue";
import commonHeader from "../../components/en/commonHeader.vue";
import { confirmEmailSuccess } from "@/api/order"
export default {
    components: { commonFooter, commonHeader},
    data(){
        return {
            realName: ''
        }
    },
    created(){
        this.realName = this.$route.query.realName
        // 确认邮箱
        if(this.$route.query.email){
            confirmEmailSuccess({
                email: this.$route.query.email
            }).then(res=>{
                this.$message.success('Successful verification')
            })
        }
    }
}
</script>

<style scoped lang='scss'>
.download-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background: #000;
}
</style>